<template>
  <FormulateForm
    ref="form"
    name="form"
  >
    <e-sidebar
      id="sidebar-stock-inversion"
      :title="$t('Novo Inventário')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      @save="saveStockInventory"
      @hidden="showSidebar = false"
    >
      <template #content>
        <b-row>
          <b-col md="12">
            <e-store-combo v-model="stockInventoryForm.storeId" />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="comments"
              v-model.number="stockInventoryForm.description"
              type="textarea"
              :label="$t('Descrição')"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="product-group-id"
              ref="groupCombo"
              v-model="stockInventoryForm.groupId"
              :label="$t('Grupo')"
              type="vue-select"
              :placeholder="$t('Selecione')"
              :options="getComboGroups"
              @input="setComboSubgroups(stockInventoryForm.groupId)"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="product-subgroup-id"
              v-model="stockInventoryForm.subgroupId"
              type="vue-select"
              :label="$t('Subgrupo')"
              :placeholder="$t('Selecione')"
              :options="getComboSubgroups"
              :disabled="!stockInventoryForm.groupId"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="product-brand-id"
              v-model="stockInventoryForm.brandId"
              :label="$t('Marca')"
              type="vue-select"
              :placeholder="$t('Selecione')"
              :options="getComboBrands"
            />
          </b-col>
          <b-col md="12">
            <FormulateInput
              id="product-department-id"
              v-model="stockInventoryForm.departmentId"
              :label="$t('Departamento')"
              type="vue-select"
              :placeholder="$t('Selecione')"
              :options="getComboDepartments"
            />
          </b-col>

          <b-col md="12">
            <FormulateInput
              v-model="stockInventoryForm.productClassifications"
              name="productClassifications"
              type="vue-select"
              :label="$t('Classificações de produto')"
              :placeholder="$t('Selecione')"
              :options="productClassificationsForInventory()"
              :instruction="$t('Selecione uma ou mais classificações de produtos')"
              multiple
            />
          </b-col>
        </b-row>
      </template>
    </e-sidebar>
  </FormulateForm>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { ESidebar } from '@/views/components'
import { averagePriceDomains, formulateHelper, productDomains } from '@/mixins'
import { mapGetters, mapActions, mapState } from 'vuex'
import EStoreCombo from '@/views/components/inputs/EStoreCombo.vue'

export default {
  components: { BRow, BCol, ESidebar, EStoreCombo },

  mixins: [formulateHelper, averagePriceDomains, productDomains],

  data() {
    return {
      showSidebar: false,
      fetching: false,
      saving: false,
    }
  },

  computed: {
    ...mapState('pages/stock/stockInventory/stockInventoryMaintain', ['stockInventoryForm']),
    ...mapGetters('pages/catalog/products', [
      'getComboDepartments',
      'getComboBrands',
      'getComboGroups',
      'getComboSubgroups',
    ]),
  },

  watch: {
    showSidebar() {
      this.cleanState()
      this.$refs.form.hideErrors()
    },
  },

  async mounted() {
    this.fetching = true
    try {
      await this.fetchProductCombos()
    } finally {
      this.fetching = false
    }
  },

  methods: {
    ...mapActions('pages/stock/stockInventory/stockInventoryMaintain', [
      'saveInventory',
      'cleanState',
    ]),
    ...mapActions('pages/catalog/products', ['setComboSubgroups', 'fetchProductCombos']),
    async saveStockInventory() {
      this.$refs.form.showErrors()
      if (this.$refs.form.isValid) {
        try {
          this.saving = true
          const result = await this.saveInventory()
          this.showSuccess({ message: this.$t('Inventário criado com sucesso') })
          this.showSidebar = false
          this.$emit('save', result)
        } catch (error) {
          if (error.response?.data?.Message) {
            await this.mxAlertNotFoundAverageCost(error.response?.data?.Message[0])
          } else {
            this.showError({ error, message: this.$t('Não foi possível concluir a operação') })
          }
        } finally {
          this.saving = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
