var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateForm",
    { ref: "form", attrs: { name: "form" } },
    [
      _c("e-sidebar", {
        attrs: {
          id: "sidebar-stock-inversion",
          title: _vm.$t("Novo Inventário"),
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "500px",
        },
        on: {
          save: _vm.saveStockInventory,
          hidden: function ($event) {
            _vm.showSidebar = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("e-store-combo", {
                          model: {
                            value: _vm.stockInventoryForm.storeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.stockInventoryForm, "storeId", $$v)
                            },
                            expression: "stockInventoryForm.storeId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "comments",
                            type: "textarea",
                            label: _vm.$t("Descrição"),
                          },
                          model: {
                            value: _vm.stockInventoryForm.description,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.stockInventoryForm,
                                "description",
                                _vm._n($$v)
                              )
                            },
                            expression: "stockInventoryForm.description",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          ref: "groupCombo",
                          attrs: {
                            id: "product-group-id",
                            label: _vm.$t("Grupo"),
                            type: "vue-select",
                            placeholder: _vm.$t("Selecione"),
                            options: _vm.getComboGroups,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.setComboSubgroups(
                                _vm.stockInventoryForm.groupId
                              )
                            },
                          },
                          model: {
                            value: _vm.stockInventoryForm.groupId,
                            callback: function ($$v) {
                              _vm.$set(_vm.stockInventoryForm, "groupId", $$v)
                            },
                            expression: "stockInventoryForm.groupId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "product-subgroup-id",
                            type: "vue-select",
                            label: _vm.$t("Subgrupo"),
                            placeholder: _vm.$t("Selecione"),
                            options: _vm.getComboSubgroups,
                            disabled: !_vm.stockInventoryForm.groupId,
                          },
                          model: {
                            value: _vm.stockInventoryForm.subgroupId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.stockInventoryForm,
                                "subgroupId",
                                $$v
                              )
                            },
                            expression: "stockInventoryForm.subgroupId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "product-brand-id",
                            label: _vm.$t("Marca"),
                            type: "vue-select",
                            placeholder: _vm.$t("Selecione"),
                            options: _vm.getComboBrands,
                          },
                          model: {
                            value: _vm.stockInventoryForm.brandId,
                            callback: function ($$v) {
                              _vm.$set(_vm.stockInventoryForm, "brandId", $$v)
                            },
                            expression: "stockInventoryForm.brandId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            id: "product-department-id",
                            label: _vm.$t("Departamento"),
                            type: "vue-select",
                            placeholder: _vm.$t("Selecione"),
                            options: _vm.getComboDepartments,
                          },
                          model: {
                            value: _vm.stockInventoryForm.departmentId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.stockInventoryForm,
                                "departmentId",
                                $$v
                              )
                            },
                            expression: "stockInventoryForm.departmentId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { md: "12" } },
                      [
                        _c("FormulateInput", {
                          attrs: {
                            name: "productClassifications",
                            type: "vue-select",
                            label: _vm.$t("Classificações de produto"),
                            placeholder: _vm.$t("Selecione"),
                            options: _vm.productClassificationsForInventory(),
                            instruction: _vm.$t(
                              "Selecione uma ou mais classificações de produtos"
                            ),
                            multiple: "",
                          },
                          model: {
                            value:
                              _vm.stockInventoryForm.productClassifications,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.stockInventoryForm,
                                "productClassifications",
                                $$v
                              )
                            },
                            expression:
                              "stockInventoryForm.productClassifications",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }