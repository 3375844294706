var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "e-filters",
        {
          attrs: { searching: _vm.fetching },
          on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
          scopedSlots: _vm._u([
            {
              key: "after_buttons",
              fn: function () {
                return [
                  _c("e-button", {
                    staticClass: "ml-1 btn-sm-block",
                    attrs: {
                      id: "download",
                      variant: "primary",
                      icon: "download",
                      busy: _vm.fetching,
                      text: _vm.$t("Baixar Relatório"),
                    },
                    on: { click: _vm.downloadReport },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "inventory-id",
                      name: "inventoryId",
                      label: _vm.$t("Id do inventário"),
                      type: "text",
                    },
                    model: {
                      value: _vm.filters.inventoryId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "inventoryId", $$v)
                      },
                      expression: "filters.inventoryId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("e-store-combo", {
                    attrs: { required: false, placeholder: _vm.$t("Todas") },
                    model: {
                      value: _vm.filters.storeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "storeId", $$v)
                      },
                      expression: "filters.storeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "2" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "type",
                      type: "vue-select",
                      label: _vm.$t("Status"),
                      placeholder: _vm.$t("Todos"),
                      options: _vm.stockInventoryStatusOptions(),
                    },
                    model: {
                      value: _vm.filters.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "status", $$v)
                      },
                      expression: "filters.status",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      id: "period",
                      type: "date-range-picker",
                      label: _vm.$t("Período"),
                      "time-picker": false,
                    },
                    model: {
                      value: _vm.filters.period,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "period", $$v)
                      },
                      expression: "filters.period",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { md: "4" } },
                [
                  _c("FormulateInput", {
                    attrs: {
                      name: "filter_productClassifications",
                      type: "vue-select",
                      label: _vm.$t("Classificações de produto"),
                      placeholder: _vm.$t("Selecione"),
                      options: _vm.productClassificationsForInventory(),
                      instruction: _vm.$t(
                        "Selecione uma ou mais classificações de produtos"
                      ),
                      multiple: "",
                    },
                    model: {
                      value: _vm.filters.productClassifications,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "productClassifications", $$v)
                      },
                      expression: "filters.productClassifications",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card-actions",
        {
          attrs: { "action-refresh": "", "show-loading": _vm.fetching },
          on: { refresh: _vm.filterData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c("b-table", {
            ref: "tablestockInventories",
            staticClass: "mb-1 bordered",
            attrs: {
              responsive: "",
              "show-empty": "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("STOCK_INVENTORY.NAME")
              ),
              fields: _vm.fields,
              items: _vm.stockInventories,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      [
                        _c("e-grid-actions", {
                          attrs: {
                            "show-update": _vm.showUpdateAction(row.item),
                            "show-delete": false,
                            "show-read-only": _vm.$can(
                              "Read",
                              "StockInventory"
                            ),
                          },
                          on: {
                            update: function ($event) {
                              return _vm.showEdit(row.item)
                            },
                            "read-only": function ($event) {
                              return _vm.showReadOnly(row.item)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "cell(status)",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("e-status-badge", {
                      attrs: {
                        status: item.status,
                        options: _vm.stockInventoryStatusOptions(),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "user-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("stock-inventory-sidebar", {
        ref: "sidebar",
        on: { save: _vm.showEdit },
      }),
      _vm.$can("Create", "StockInventory")
        ? _c("fab", {
            attrs: { "main-tooltip": _vm.$t("Adicionar Inventário") },
            on: { click: _vm.showCreate },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }