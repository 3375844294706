<template>
  <div>
    <e-filters
      :searching="fetching"
      @search="filterData"
      @reset="resetFiltersLocal"
    >
      <template #after_buttons>
        <e-button
          id="download"
          class="ml-1 btn-sm-block"
          variant="primary"
          icon="download"
          :busy="fetching"
          :text="$t('Baixar Relatório')"
          @click="downloadReport"
        />
      </template>
      <b-row>
        <b-col md="2">
          <FormulateInput
            id="inventory-id"
            v-model="filters.inventoryId"
            name="inventoryId"
            :label="$t('Id do inventário')"
            type="text"
          />
        </b-col>

        <b-col md="4">
          <e-store-combo
            v-model="filters.storeId"
            :required="false"
            :placeholder="$t('Todas')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="type"
            v-model="filters.status"
            type="vue-select"
            :label="$t('Status')"
            :placeholder="$t('Todos')"
            :options="stockInventoryStatusOptions()"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            id="period"
            v-model="filters.period"
            type="date-range-picker"
            :label="$t('Período')"
            :time-picker="false"
          />
        </b-col>

        <b-col md="4">
          <FormulateInput
            v-model="filters.productClassifications"
            name="filter_productClassifications"
            type="vue-select"
            :label="$t('Classificações de produto')"
            :placeholder="$t('Selecione')"
            :options="productClassificationsForInventory()"
            :instruction="$t('Selecione uma ou mais classificações de produtos')"
            multiple
          />
        </b-col>
      </b-row>
    </e-filters>

    <b-card-actions
      action-refresh
      :show-loading="fetching"
      @refresh="filterData"
    >
      <div slot="title">
        <e-page-size-selector
          :per-page="paging.pageSize"
          @change="pageSizeChange"
        />
      </div>

      <b-table
        ref="tablestockInventories"
        responsive
        show-empty
        striped
        class="mb-1 bordered"
        :empty-text="getEmptyTableMessage($tc('STOCK_INVENTORY.NAME'))"
        :fields="fields"
        :items="stockInventories"
        no-local-sorting
        :sort-by.sync="sorting.sortBy"
        :sort-desc.sync="sorting.sortDesc"
        @sort-changed="getData"
      >
        <template #cell(action)="row">
          <span>
            <e-grid-actions
              :show-update="showUpdateAction(row.item)"
              :show-delete="false"
              :show-read-only="$can('Read', 'StockInventory')"
              @update="showEdit(row.item)"
              @read-only="showReadOnly(row.item)"
            />
          </span>
        </template>

        <template #cell(status)="{ item }">
          <e-status-badge
            :status="item.status"
            :options="stockInventoryStatusOptions()"
          />
        </template>

        <!-- <template #cell(purchaseOrder_invoiceNumber)="row">
          <router-link
            v-if="row.item.purchaseOrder"
            :to="{ name: 'purchase-order-details', params: { id: row.item.purchaseOrder.id } }"
            class="hyperlink"
          >
            <feather-icon icon="ExternalLinkIcon" />
            {{ row.item.purchaseOrder.invoiceNumber }}
          </router-link>
          <span v-else>-</span>
        </template> -->
      </b-table>

      <b-row>
        <b-col cols="6">
          <e-pagination-summary
            :current-page="paging.currentPage"
            :per-page="paging.pageSize"
            :total="paging.rowCount"
            :total-on-page="paging.rowsInCurrentPage"
          />
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="paging.currentPage"
            align="right"
            :total-rows="paging.rowCount"
            :per-page="paging.pageSize"
            aria-controls="user-table"
            @change="pageChange"
          />
        </b-col>
      </b-row>
    </b-card-actions>

    <stock-inventory-sidebar
      ref="sidebar"
      @save="showEdit"
    />

    <fab
      v-if="$can('Create', 'StockInventory')"
      :main-tooltip="$t('Adicionar Inventário')"
      @click="showCreate"
    />
  </div>
</template>

<script>
import { BRow, BCol, BPagination, BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  EPageSizeSelector,
  EPaginationSummary,
  EFilters,
  EStoreCombo,
  EGridActions,
  EStatusBadge,
  EButton,
} from '@/views/components'
import fab from '@/views/components/FAB.vue'
import { downloader, formulateValidation, productDomains, stockDomain } from '@/mixins'
import { mapActions, mapGetters, mapState } from 'vuex'
import StockInventorySidebar from './components/StockInventorySidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
    BPagination,
    BTable,
    EPaginationSummary,
    EPageSizeSelector,
    fab,
    EFilters,
    EStoreCombo,
    StockInventorySidebar,
    EGridActions,
    EStatusBadge,
    EButton,
  },

  mixins: [formulateValidation, stockDomain, productDomains, downloader],

  data() {
    return {
      fetching: false,
      saving: false,
      skus: [],
    }
  },

  computed: {
    ...mapGetters('app', ['storeOptions']),
    ...mapState('pages/stock/stockInventory', {
      stockInventories: 'stockInventories',
      paging: 'paging',
      sorting: 'sorting',
      filters: 'filters',
    }),

    showUpdateAction() {
      const { COMPLETED, CANCELED, INVOICE_PENDING } = this.stockInventoryStatusEnum
      return item =>
        this.$can('Update', 'StockInventory') &&
        ![COMPLETED, CANCELED, INVOICE_PENDING].some(status => status === item.status)
    },

    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          class: 'text-center',
          thStyle: { width: '100px' },
        },
        {
          label: this.$t('Id'),
          key: 'id',
          class: 'text-center',
          thStyle: { width: '80px' },
          sortable: true,
        },
        {
          label: this.$t('Data/Hora'),
          key: 'createdDate',
          class: 'text-center',
          thStyle: { width: '140px' },
          formatter: value => this.$options.filters.datetime(value),
          sortable: true,
        },
        {
          label: this.$t('Status'),
          key: 'status',
          class: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Loja'),
          key: 'store.id',
          formatter: (val, index, item) => this.$options.filters.storeName(item.store),
          sortable: true,
          thStyle: { minWidth: '150px' },
        },
        {
          label: this.$t('Parâmetros'),
          key: 'products',
          formatter: (val, index, item) => this.getCategoryName(item),
        },
        {
          label: this.$t('Qtd. Produtos'),
          key: 'skusCount',
          class: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => this.$options.filters.number(value, 0),
          sortable: true,
        },
        {
          label: this.$t('Perc. Completado'),
          key: 'percentCompleted',
          class: 'text-center',
          thStyle: { width: '100px' },
          formatter: value => this.$options.filters.percentage(value),
          sortable: true,
        },
        {
          label: this.$t('Diferença Apurada'),
          key: 'differenceValue',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '150px' },
          formatter: value => this.$options.filters.currency(value),
          sortable: true,
        },
      ]
    },

    today() {
      return new Date()
    },
  },

  mounted() {
    this.getData()
  },

  methods: {
    ...mapActions('pages/stock/stockInventory', [
      'fetchStockInventories',
      'generateReport',
      'resetFilters',
      'setCurrentPage',
      'setPageSize',
      'setFilters',
    ]),
    ...mapActions('pages/stock/stockInventory/stockInventoryMaintain', {
      maintainCleanState: 'cleanState',
    }),

    async getData() {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchStockInventories()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    getCategoryName(item) {
      const { group, subgroup, brand, department } = item
      return [
        group ? group.name : '-',
        subgroup ? subgroup.name : '-',
        brand ? brand.name : '-',
        department ? department.name : '-',
      ].join(' | ')
    },

    filterData() {
      this.setCurrentPage(1)
      this.getData()
    },

    resetFiltersLocal() {
      this.resetFilters()
      this.filterData()
    },

    pageSizeChange(pageSize) {
      this.setPageSize(pageSize)
      this.getData()
    },

    pageChange(currentPage) {
      this.setCurrentPage(currentPage)
      this.getData()
    },

    showCreate() {
      this.$refs.sidebar.showSidebar = true
    },

    showEdit(stockInventory) {
      this.maintainCleanState()
      this.$router.push({ name: 'stock-inventory-maintain', params: { id: stockInventory.id } })
    },

    showReadOnly(stockInventory) {
      this.maintainCleanState()
      this.$router.push({ name: 'stock-inventory-read-only', params: { id: stockInventory.id } })
    },

    async downloadReport() {
      try {
        this.fetching = true
        const reportData = await this.generateReport()

        if (reportData) {
          this.forceFileDownloadXlsx(reportData, `Inventarios`)
        } else {
          this.showError({ message: this.$t('Não foi possível recuperar os dados do relatório') })
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.fetching = false
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
